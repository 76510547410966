import {createPortal} from 'react-dom';

import LightBox from './LightBox';

const LightBoxNode = document.getElementById('lightbox');

const LightBoxWrapper = ({children, ...props}) => createPortal(
    <LightBox {...props}>
        {children}
    </LightBox>,
    LightBoxNode,
);

export default LightBoxWrapper;
