import {Region} from '../actions';

const initialState = window.RegionStore || {
    regions: [],
    regionID: null,
};

const reducers = {
    [Region.change]: (state, regionID) => ({regionID}),
};

export default {
    initialState,
    reducers,
};
