import 'regenerator-runtime';

import jQuery from 'jquery';

import {createAction} from 'redux-actions';

import {TypeLoc} from './constants';

import {getBy, getById} from '../utils/find';

import {Types} from './components/ShopPopUp/utils';

import {
    Animation,
    Data,
    Date,
    History,
    Product,
    Region,
    Resolution,
    Request,
    Settings,
    ShopPopUp,
    Timer,
} from './actions';

export const showShopPopUp = createAction(ShopPopUp.show, (type, context) => ({type, context}));

export const hideShopPopUp = createAction(ShopPopUp.hide)(null);

const ensureAvailability = (type, getContext, whenAvailable) => (dispatch, getState) => {
    const state = getState();

    if (state.type === TypeLoc) {
        const context = getContext(state);

        if (context.available === false) return dispatch(showShopPopUp(type, context));
    }

    return whenAvailable(dispatch, state);
};

/**
 * @param {Number} timerID
 */
const timerStarted = createAction(Timer.started);

/**
 * @param {Number} timerID
 */
const timerCancelled = createAction(Timer.cancelled);

const cancelTimer = (dispatch, getState) => {
    const {timer} = getState();

    if (timer) {
        clearTimeout(timer);
        dispatch(timerCancelled(timer));
    }
};

const getReloadData = ({offset, index, resolution, regionID, productID, locationID}) => {
    const data = {offset, index, resolution, region: regionID};

    if (productID) data.product = productID;
    else if (locationID) data.location = locationID;

    return data;
};

const RequestInterval = 1000 * 60;

/**
 * @param {Number} [timeout]
 */
export const startRequest = (timeout = RequestInterval) => (dispatch, getState) => {
    const {NoAutoReload} = getState();

    const callback = (immediate = false) => {
        const {reloadURL, ...state} = getState();

        const request = jQuery.ajax(reloadURL, {
            method: 'GET',
            data: getReloadData(state),
            dataType: 'json',
            success: response => dispatch(requestSucceeded(response, request)),
            error: (...args) => dispatch(requestFailed(...args)),
        });

        request.immediate = immediate;

        return dispatch(requestStarted(request));
    };

    if (timeout === 0) return callback(true);
    else if (NoAutoReload && timeout === RequestInterval) return null;
    else return dispatch(timerStarted(setTimeout(callback, timeout)));
};

const requestCancelled = createAction(Request.cancelled);

const cancelRequest = (dispatch, getState) => {
    const {request} = getState();

    if (request) {
        request.abort();
        dispatch(requestCancelled(request));
    }
};

const requestStarted = createAction(Request.started);

const requestSucceededCreator = createAction(Request.succeeded);

const requestSucceeded = ({success, message, ...data}, request) => dispatch => {
    dispatch(requestSucceededCreator(request));
    dispatch(dataReloaded({...data, isInitial: false}));
    dispatch(startRequest());
};

const requestFailedCreator = createAction(Request.failed);

const requestFailed = (request, status, error) => dispatch => {
    if (request.status) {
        const {status} = request;

        if (status > 399 && status < 500) return location.reload(true);
    }

    dispatch(requestFailedCreator(request, status, error));
    dispatch(startRequest(RequestInterval / 2));
};

/**
 * @param {Number} productID
 * @param {Boolean} isFavourite
 */
const setFavouriteAction = createAction(Product.setFavourite, (productID, isFavourite) => ({productID, isFavourite}));

/**
 * @param {Number} productID
 */
export const toggleFavourite = productID => (dispatch, getState) => {
    const {products, toggleFavouriteURL, CSRFMiddlewareToken: csrfmiddlewaretoken} = getState();
    const {id: product, isFavourite} = products[productID];

    jQuery.ajax(toggleFavouriteURL, {
        method: 'POST',
        dataType: 'json',
        data: {
            status: isFavourite ? 0 : 1,
            csrfmiddlewaretoken,
            product,
        },
        error: ({status, responseJSON}) => {
            if (status === 400 && 'isFavourite' in responseJSON) {
                const {isFavourite} = responseJSON;

                dispatch(setFavouriteAction(productID, isFavourite));
            }
        },
    });

    dispatch(setFavouriteAction(productID, !isFavourite));
};

/**
 * @param {Boolean} showFavouritesOnly
 */
const showFavouritesOnlyAction = createAction(Settings.showFavouritesOnly);

export const toggleShowFavouritesOnly = (dispatch, getState) => {
    const {showFavouritesOnly} = getState();

    return dispatch(showFavouritesOnlyAction(!showFavouritesOnly));
};

/**
 * @param {Object} payload
 */
export const dataReloaded = createAction(Data.reloaded);

/**
 * @param {Object} state
 */
const popStateAction = createAction(History.popState);

export const popState = state => (dispatch, getState) => {
    const {offset, resolution} = getState();

    dispatch(popStateAction(state));

    if (state.offset !== offset) dispatch(dateChange(state.offset));
    if (state.resolution !== resolution) dispatch(changeResolution(state.resolution));
};

const reload = (dispatch, getState) => {
    cancelTimer(dispatch, getState);
    cancelRequest(dispatch, getState);
    dispatch(startRequest(0));
};

/**
 * @param {Number} offset
 */
const dateChangeCreator = createAction(Date.change);

/**
 * @param {Number} offset
 */
export const dateChange = offset => {
    const getContext = ({dates}) => getBy('offset', offset, dates);
    const whenAvailable = (dispatch, state) => {
        dispatch(dateChangeCreator(offset));
        reload(dispatch, () => state);
    };

    return ensureAvailability(Types.Date, getContext, whenAvailable);
};

const startAnimation = createAction(Animation.start);

export const stopAnimation = createAction(Animation.stop);

export const toggleAnimation = (dispatch, getState) => {
    const {isAnimating} = getState();

    return dispatch((isAnimating ? stopAnimation : startAnimation)());
};

export const animationSpeedChange = createAction(Animation.speed);

export const changeAnimationStart = createAction(Animation.startIndex);

export const changeAnimationStop = createAction(Animation.stopIndex);

export const animationIndex = createAction(Animation.index);

const changeRegionAction = createAction(Region.change);

export const baseChangeRegion = (id, buildURL, confirmChangeAction) => {
    const getContext = ({regions}) => getById(id, regions);
    const whenAvailable = async (dispatch, state) => {
        const {regionID} = state;

        if (id === regionID) return;

        if (confirmChangeAction) {
            const confirmed = await dispatch(confirmChangeAction(id));

            if (confirmed === false) return;
        }

        dispatch(changeRegionAction(id));

        window.location.href = buildURL({...state, regionID: id});
    };

    return ensureAvailability(Types.Region, getContext, whenAvailable);
};

const changeResolutionAction = createAction(Resolution.change);

export const changeResolution = resolution => (dispatch, getState) => {
    dispatch(changeResolutionAction(resolution));
    reload(dispatch, getState);
};
