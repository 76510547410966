export const Animation = {
    start: 'Animation.start',
    stop: 'Animation.stop',
    startIndex: 'Animation.start-index',
    stopIndex: 'Animation.stop-index',
    speed: 'Animation.speed',
    panel: 'Animation.panel',
    index: 'Animation.index',
};

export const Data = {
    reloaded: 'data.reloaded',
};

export const Date = {
    change: 'date.change',
};

export const Environment = {
    dragging: 'environment.dragging',
    canPreload: 'environment.can-preload',
};

export const History = {
    popState: 'history.pop-state',
};

export const Index = {
    change: 'index.change',
};

export const Product = {
    change: 'product.change',
    setFavourite: 'product.set-favourite',
    legend: 'product.legend',
};

export const Region = {
    change: 'region.change',
};

export const Resolution = {
    change: 'resolution.change',
};

export const Request = {
    started: 'request.started',
    succeeded: 'request.succeeded',
    failed: 'request.failed',
    cancelled: 'request.cancelled',
};

export const Settings = {
    showFavouritesOnly: 'settings.show-favourites-only',
    showMatrix: 'settings.show-matrix',
};

export const ShopPopUp = {
    show: 'shop-pop-up.show',
    hide: 'shop-pop-up.hide',
};

export const Timer = {
    started: 'timer.started',
    cancelled: 'timer.cancelled',
};
