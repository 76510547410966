/* global CurrencySymbol */

import {connect} from 'react-redux';
import classNames from 'classnames';

const Item = ({title, level, available, onClick}) => (
    <li className={classNames('region', `level-${level}`, {available})} onClick={onClick}>
        {available ? '' : CurrencySymbol} {title}
    </li>
);

const mapDispatchToProps = (dispatch, {id, changeRegion}) => ({
    onClick: () => dispatch(changeRegion(id)),
});

export default connect(undefined, mapDispatchToProps)(Item);
