import jQuery from 'jquery';

import {datasetSupported} from 'topmeteo/utils/compatibility';

import GoogleAnalytics, {isActive} from './util';

export const track = (category, action, label, value) => (
    GoogleAnalytics('send', 'event', category, action, label, value)
);

const Properties = [
    'category',
    'action',
    'label',
    'value',
];

let getData;

if (datasetSupported) {
    const keys = [
        'eventCategory',
        'eventAction',
        'eventLabel',
        'eventValue',
    ];

    getData = node => (node.dataset && node.dataset.event) ? keys.map(key => node.dataset[key]) : null;
} else {
    const keys = Properties.map(key => `event-${key}`);

    getData = node => {
        const jqNode = jQuery(node);

        return jqNode.data('event') ? keys.map(key => jqNode.data(key)) : null;
    }
}

export const getEventData = node => {
    if (node === null) return null;

    const data = getData(node);

    if (data) return data;

    return getEventData(node.parentElement);
};

const createEventData = (category, action, label, value) => {
    const data = {};

    if (isActive) {
        const values = {category, action, label, value};

        Properties.forEach(key => {
            const value = values[key];

            if (typeof value === 'undefined' || value === null) return;

            data[`data-event-${key}`] = value;
        });

        data['data-event'] = 1;
    }

    return data;
};

export default createEventData;
