import {connect} from 'react-redux';

import {getCurrentRegion} from '../../selectors';

import {createMetSelectWrapper, MetSelectInput, MetSelectList} from '../MetSelectWrapper';

import Item from './Item';
import Option from './Option';

const RegionSelect = ({id, title, abbreviation, regions, onChange, ...props}) => (
    <div className="met-select-wrapper has-mobile-short-label">
        <span className="emulate-short-label">
            {abbreviation}
        </span>
        <select className="met-select" value={id} onChange={onChange}>
            {regions.map(region => <Option {...region} key={region.id} />)}
        </select>
        <div className="styled-dropdown">
            <MetSelectInput {...props} value={title} />
            <MetSelectList {...props}>
                {regions.map(region => <Item {...props} {...region} key={region.id} />)}
            </MetSelectList>
        </div>
    </div>
);

const RegionSelectWrapper = createMetSelectWrapper(RegionSelect);

const mapStateToProps = state => {
    const {regions} = state;
    const {id, title, abbreviation} = getCurrentRegion(state);

    return {
        regions,
        id,
        title,
        abbreviation,
    };
};

const mapDispatchToProps = (dispatch, {changeRegion}) => ({
    onChange: ({target: {value}}) => dispatch(changeRegion(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionSelectWrapper);
