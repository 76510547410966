/* global ShopPopUpURLs */

import {connect} from 'react-redux';

import {gettext, interpolate} from 'topmeteo/utils/i18n';

import {hideShopPopUp} from '../../actionCreators';

import LightBox from '../LightBox';

import {Types} from './utils';

const Headline = gettext('Not available');

const DefaultMessage = gettext('Click here to visit our shop and extend your subscription.');

const Messages = {
    [Types.Date]: gettext('Click here to customize your subscriptions.'),
};

const Texts = {
    [Types.Date]: gettext('The selection is not part of your subscription.'),
    [Types.Region]: gettext('Your subscription does not include region %(title)s.'),
    [Types.Location]: gettext('Your subscription does not include location %(title)s.'),
};

const URLs = {
    [Types.Date]: ShopPopUpURLs.abos,
};

const getMessage = type => Messages[type] || DefaultMessage;

const getText = (type, context) => interpolate(Texts[type], context);

const getURL = type => URLs[type] || ShopPopUpURLs.shop;

const ShopPopUp = ({shopPopUp, onClose}) => {
    if (shopPopUp === null) return null;

    const {type, context} = shopPopUp;
    const text = getText(type, context);
    const message = getMessage(type);
    const url = getURL(type);

    return (
        <LightBox headline={Headline} onClose={onClose} hint>
            <p className="shop-pop-up-text">
                <span>{text} </span>
                <a href={url}>{message}</a>
            </p>
        </LightBox>
    );
};

const mapStateToProps = ({shopPopUp}) => ({shopPopUp});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideShopPopUp),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPopUp);
