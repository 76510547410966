import {createReducer} from '../utils/redux';

import region from '../general/reducer/region';
import shopPopUp from '../general/reducer/shopPopUp';

const initialState = {
    ...region.initialState,
    ...shopPopUp.initialState,
};

const reducers = {
    ...region.reducers,
    ...shopPopUp.reducers,
};

export default createReducer(initialState, reducers);
