import {createPortal} from 'react-dom';

const cache = {};

const getElement = id => (
    id in cache ? cache[id] : cache[id] = document.getElementById(id)
);

const getContainer = container => (
    typeof container === 'string' ? getElement(container) : container
);

const Portal = ({container, children}) => {
    const node = getContainer(container);

    return node && createPortal(children, node);
};

export default Portal;
