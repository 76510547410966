import repeat from 'lodash/repeat';
import classNames from 'classnames';

const NonBreakableSpace = '&nbsp;';

const applyLevel = (title, level, available) => ({
    __html: repeat(NonBreakableSpace, level * 4) + (available ? '' : '€ ') + title,
});

const Option = ({id, title, level, available}) => (
    <option
        key={id}
        value={id}
        className={classNames('region', {available})}
        dangerouslySetInnerHTML={applyLevel(title, level, available)}
    />
);

export default Option;
