import classNames from 'classnames';

import {gettext} from 'topmeteo/utils/i18n';

const CloseLabel = gettext('Close');

const Style = {display: 'block'};

const LightBox = ({className, headline, children, hint, onClose}) => (
    <div className={classNames('lightbox-outer', className)} style={Style}>
        <div className="overlay" />
        <div className={classNames('lightbox', {hint})}>
            <div className="lightbox-inner">
                {headline && <h3>{headline}</h3>}
                {children}
                <div className="close">
                    <a onClick={onClose}>
                        <span className="sr-only">
                            {CloseLabel}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
);

LightBox.defaultProps = {
    hint: false,
};

export default LightBox;
