import EventListener, {withOptions} from 'react-event-listener';

import {isActive} from '../util';
import {getEventData, track} from '../event';

const handleClick = event => {
    const data = getEventData(event.target);

    if (data) track(...data);
};

const onClick = withOptions(handleClick, {passive: true, capture: true});

const EventTracker = () => (
    isActive && <EventListener target={window} onClick={onClick} />
);

export default EventTracker;
