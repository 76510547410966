import {render} from 'react-dom';
import {Provider} from 'react-redux';

/* styles */
import '../../styles/require.less';

import '../utils/legacy';

import {createStore} from '../utils/redux';

import reducer from './reducer';
import {changeRegion} from './actionCreators';

import Base from './components/Base';

const RootNode = document.getElementById('mobile-detect');

const store = createStore(reducer, 'TopMeteo');

render(
    <Provider store={store}>
        <Base changeRegion={changeRegion} />
    </Provider>,
    RootNode,
);
