import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware, createStore as baseCreateStore} from 'redux';

/**
 * @param {object} initialState
 * @param {object} handlers
 * @returns {function}
 */
export function createReducer(initialState, handlers) {
    return function reducer(state = initialState, {type, payload}) {
        if (handlers.hasOwnProperty(type)) {
            return {
                ...state,
                ...handlers[type](state, payload),
            };
        }

        return state;
    };
}

/**
 * @param {function} rootReducer
 * @param {String} name
 * @returns {Store}
 */
export const createStore = (rootReducer, name) => (
    baseCreateStore(rootReducer, composeWithDevTools({name})(applyMiddleware(thunk)))
);
