import Portal from '../Portal';
import Select from './Wrapper';

const Wrapper = ({mobile, ...props}) => {
    const component = <Select {...props} />;

    return [
        <Portal key="desktop" container="region-select">{component}</Portal>,
        mobile && <Portal key="mobile" container="mobile-region-select">{component}</Portal>,
    ];
};

Wrapper.defaultProps = {
    mobile: true,
};

export default Wrapper;
