import {createSelector} from 'reselect';

import {gettext} from 'topmeteo/utils/i18n';

import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import findLast from 'lodash/findLast';

const FavoritesLabel = gettext('Favorites');
const ProductsLabel = gettext('Products');

export const getCategories = createSelector(
    ({categories}) => categories,
    ({products}) => products,
    (categories, mapping) => categories.map(category => ({
        ...category,
        products: category.products.map(id => mapping[id]),
    })),
);

export const getAvailableCategories = createSelector(
    getCategories,
    ({showFavouritesOnly}) => showFavouritesOnly,
    (categories, showFavouritesOnly) => (
        categories
            .map(category => ({
                ...category,
                /* do not show unavailable products or non-favourite products when non-favourites are hidden */
                products: category.products.filter(({isAvailable, isFavourite}) => (
                    isAvailable && (isFavourite || showFavouritesOnly === false)
                )),
            }))
            /* do not show categories without any visible products */
            .filter(({products}) => products.length > 0)
    ),
);

export const getProducts = createSelector(
    getCategories,
    categories => categories.reduce((products, category) => products.concat(category.products), []),
);

export const getAvailableProducts = createSelector(
    getProducts,
    products => products.filter(({isAvailable}) => isAvailable),
);

export const getSortedProducts = createSelector(
    getAvailableProducts,
    products => {
        return sortBy(products, ({isFavourite}) => isFavourite ? 0 : 1);
    },
);

export const getProductGroups = createSelector(
    getAvailableProducts,
    products => {
        const grouped = groupBy(products, 'isFavourite');

        return [
            {label: FavoritesLabel, products: grouped[true] || []},
            {label: ProductsLabel, products: grouped[false] || []},
        ];
    },
);

export const getProduct = createSelector(
    ({products}) => products,
    ({productID}) => productID,
    (products, productID) => products[productID] || null,
);

const getBaseProduct = add => createSelector(
    getSortedProducts,
    getProduct,
    (products, product) => {
        const index = products.indexOf(product) + add;

        if (index >= 0 && index < products.length) {
            return products[index].id;
        }

        return null;
    },
);

export const getNextProduct = getBaseProduct(+1);

export const getPreviousProduct = getBaseProduct(-1);

export const getColumn = createSelector(
    getProduct,
    ({index}) => index,
    ({columns, resolution}, index) => columns[Math.floor(index / resolution)],
);

export const getIndices = createSelector(
    getProduct,
    ({index}) => index,
    ({indices}) => indices,
    (product, index, indices) => {
        if (product === null || index === null) return indices.map(index => ({
            ...index,
            available: false,
            current: false,
        }));

        const {columns, resolution} = product;
        const column = Math.floor(index / resolution);
        const {start, end} = columns[column];

        return indices.map((index, idx) => ({
            ...index,
            available: idx % resolution === 0 && columns[idx / resolution].available,
            current: start <= idx && idx < end,
        }));
    },
);

export const getAvailableIndices = createSelector(
    getIndices,
    indices => indices.filter(({available}) => available),
);

export const getChunks = createSelector(
    getProduct,
    ({id, columns}) => (
        columns.map(column => ({
            key: `${id}-${column.start}`,
            index: column.start,
            ...column,
        }))
    ),
);

export const getAvailableChunks = createSelector(
    getChunks,
    ({animationStart}) => animationStart,
    ({animationStop}) => animationStop,
    (chunks, start, stop) => chunks.filter(
        ({index, available}) => available && index >= start && index <= stop,
    ),
);

export const getCurrentDate = createSelector(
    ({dates}) => dates,
    ({offset}) => offset,
    (dates, offset) => find(dates, {offset}),
);

const getAvailableDates = createSelector(
    ({dates}) => dates,
    dates => dates.filter(({available}) => available),
);

export const getMaxOffset = createSelector(
    getAvailableDates,
    dates => {
        const {offset} = dates[dates.length - 1];

        return offset;
    },
);

export const getMinOffset = createSelector(
    getAvailableDates,
    dates => {
        const {offset} = dates[0];

        return offset;
    },
);

export const getNextOffset = createSelector(
    getMaxOffset,
    ({offset}) => offset,
    (maxOffset, currentOffset) => (
        currentOffset < maxOffset ? {offset: currentOffset + 1} : null
    ),
);

export const getPreviousOffset = createSelector(
    getMinOffset,
    ({offset}) => offset,
    (minOffset, currentOffset) => (
        currentOffset > minOffset ? {offset: currentOffset - 1} : null
    ),
);

export const getNextImage = createSelector(
    getProduct,
    getMaxOffset,
    ({index}) => index,
    ({offset}) => offset,
    ({columns}, maxOffset, currentIndex, currentOffset) => {
        if (columns.length === 1) return null;

        const next = find(columns, ({available, start}) => (available && start > currentIndex));

        if (next) return {index: next.start};

        else if (currentOffset < maxOffset) return {
            offset: currentOffset + 1,
            index: 0,
        };

        return null;
    },
);

export const getPreviousImage = createSelector(
    getProduct,
    getMinOffset,
    ({index}) => index,
    ({offset}) => offset,
    ({columns}, minOffset, currentIndex, currentOffset) => {
        if (columns.length === 1) return null;

        const previous = findLast(columns, ({available, start}) => (available && start < currentIndex));

        if (previous) return {index: previous.start};

        else if (currentOffset > minOffset) return {
            offset: currentOffset - 1,
            index: columns[columns.length - 1].start,
        };

        return null;
    },
);

export const getCurrentRegion = createSelector(
    ({regions}) => regions,
    ({regionID}) => regionID,
    (regions, id) => find(regions, {id}),
);
