import {Fragment} from 'react';

import ShopPopUp from 'topmeteo/general/components/ShopPopUp';
import RegionSelect from 'topmeteo/general/components/RegionSelect';

import EventTracker from 'topmeteo/google-analytics/components/EventTracker';

const Base = props => (
    <Fragment>
        <EventTracker />
        <ShopPopUp />
        <RegionSelect {...props} />
    </Fragment>
);

export default Base;
