/* eslint-disable */

(function ($) { $(function(){
    /**
     * data-classtoggle action
     */
    $('*[data-toggleclass]').click(function(){
        $($(this).data('target')).toggleClass(String($(this).data('toggleclass')));
    });

    /**
     * data-toggle action
     */
    $('*[data-toggle]').click(function(){
        $($(this).data('toggle')).toggle();
    });

    /**
     * data-menu action
     */
    $('*[data-menu]').click(function(){
        var $menu = $($(this).data('menu')),
            state = $menu.is(':hidden');
        $(this).attr('aria-expanded',state);
        $menu.attr('aria-expanded',state);
        $menu.toggle();
    });

    $('.menu-layer').click(function(e){
        $('*[data-menu]').trigger('click');
    });

    /**
     * Using the data-panel attribute an element can toggle the class panel-open on the
     * referenced element
     */
    $('*[data-panel]').click(function(){
        $($(this).data('panel')).toggleClass('panel-open');
        // signal the panel open as event
        window.setTimeout(function(){
            $(window).trigger('topmeteo:panel');
        }, 25);
    });

    (function (body) {
        if (body.hasClass('first-steps-new-layout')) {
            $('.panel').on('click', '.toggleblock-head a', function () {
                var selector = $(this).attr('href'),
                    jqContent = $(selector),
                    video = jqContent.find('video');

                if (video.length) {
                    video.get(0).play();
                }
            });
        }
    })($(document.body));
})})(jQuery);
