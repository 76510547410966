import {ShopPopUp} from '../actions';

const initialState = {
    shopPopUp: null,
};

const reducer = (state, shopPopUp) => ({shopPopUp});

const reducers = {
    [ShopPopUp.show]: reducer,
    [ShopPopUp.hide]: reducer,
};

export default {
    initialState,
    reducers,
};
