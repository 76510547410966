import {useCallback, useState} from 'react';
import EventListener from 'react-event-listener';
import classNames from 'classnames';

export const createMetSelectWrapper = MetSelect => {
    const displayName = MetSelect.displayName || MetSelect.name || 'Select';

    const MetSelectWrapper = props => {
        const [focus, setFocus] = useState(false);

        const handleMetSelectClick = useCallback(
            event => {
                event.preventDefault();
                event.stopPropagation();
                setFocus(focus => !focus);
            },
            [setFocus],
        );

        const events = {};

        if (focus) events.onClick = handleMetSelectClick;

        return (
            <EventListener target={window} {...events}>
                <MetSelect {...props} onMetSelectClick={handleMetSelectClick} focus={focus} />
            </EventListener>
        );
    };

    MetSelectWrapper.defaultProps = {
        maxHeight: '70vh',
        placement: 'bottom-left',
    };

    MetSelectWrapper.displayName = `MetSelect(${displayName})`;

    return MetSelectWrapper;
};

export const MetSelectInput = ({value, focus, className, onMetSelectClick, metSelectRef}) => (
    <input
        className={classNames('selectedInput', 'met-select', className, {focus})}
        onClick={onMetSelectClick}
        ref={metSelectRef}
        value={value}
        type="text"
        readOnly
    />
);

export const MetSelectList = ({maxHeight, placement, children, style}) => (
    <ol style={{...style, maxHeight}} data-placement={placement}>
        {children}
    </ol>
);
